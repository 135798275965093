import {faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash} from "@fortawesome/free-solid-svg-icons";
import {
    TURN_OFF_AUDIO,
    TURN_ON_AUDIO,
    TURN_OFF_VIDEO,
    TURN_ON_VIDEO,
} from "../constants/roomConstants";

//Default icon setup
let defaultState = {
    audioIcon: faMicrophone,
    audioAction: TURN_OFF_AUDIO,
    videoIcon: faVideoSlash,
    videoAction: TURN_ON_VIDEO
}

export const roomReducer = (state = {}, action) => {

    //START - Default icon setup for Agent
    if ('undefined' !== typeof (action)
        && 'undefined' !== typeof (action.payload)
        && 'undefined' !== typeof (action.payload.userType)
    ) {
        if (action.payload.userType == 'Agent') {
            defaultState = {
                audioIcon: faMicrophone,
                audioAction: TURN_OFF_AUDIO,
                videoIcon: faVideo,
                videoAction: TURN_OFF_VIDEO
            }
        }
    }
    //End - Default icon setup for Agent

    switch (action.type) {
        case TURN_ON_AUDIO:
            return {
                audioIcon: faMicrophone,
                audioAction: TURN_OFF_AUDIO,
                videoIcon: state.videoIcon,
                videoAction: state.videoAction
            };
        case TURN_OFF_AUDIO:
            return {
                audioIcon: faMicrophoneSlash,
                audioAction: TURN_ON_AUDIO,
                videoIcon: state.videoIcon,
                videoAction: state.videoAction
            };
        case TURN_ON_VIDEO:
            return {
                audioIcon: state.audioIcon,
                audioAction: state.audioIcon,
                videoIcon: faVideo,
                videoAction: TURN_OFF_VIDEO
            };
        case TURN_OFF_VIDEO:
            return {
                audioIcon: state.audioIcon,
                audioAction: state.audioIcon,
                videoIcon: faVideoSlash,
                videoAction: TURN_ON_VIDEO
            };
        default:
            return defaultState;
    }
}
