import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt, faTv, faPhoneSquare} from "@fortawesome/free-solid-svg-icons";
import Video from "twilio-video";
import Participant from "../Participant";
import {
    toggleAudioTrack,
    toggleVideoTrack,
    copyURL,
    toggleCamera,
    logout,
    endTour,
    getDeviceType,
    shareScreen,
    screenTrackSubscribed,
    screenTrackUnsubscribed,
    handleSpeakerChange
} from "../actions/roomActions";

const RoomScreen = ({handleLogout}) => {
    const dispatch = useDispatch();
    const [token, setToken] = useState('');
    const [room, setRoom] = useState(null);
    const [roomName, setRoomName] = useState('');
    const [participants, setParticipants] = useState([]);
    const [userType, setUserType] = useState('');
    const [cameraDevices, setCameraDevices] = useState([]);
    const data = useSelector(state => state.loginDetails);
    const tokenDetails = useSelector((state) => state.tokenDetails);
    const roomData = useSelector(state => state.roomDetail);
    const removeElements = (elms) => elms.forEach(el => el.remove());

    //Twilio Connection
    useEffect(() => {
        const participantConnected = participant => {
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        };

        const participantDisconnected = participant => {
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
        };

        Video.connect(token, {
            name: roomName,
            networkQuality: {
                local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
                remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
            },
            dominantSpeaker: true
        }).then(room => {
            room.on('dominantSpeakerChanged', participant => {
                console.log(participant);
                handleSpeakerChange(participant);
            });
            setRoom(room);
            if (true === room.localParticipant.identity.includes('*agent')) {
                // Change Network Quality verbosity levels after joining the Room
                room.localParticipant.setNetworkQualityConfiguration({
                    local: 2,
                    remote: 1
                });
                navigator.mediaDevices.enumerateDevices().then(gotDevices);
                sessionStorage.setItem("cameraIndex", "0");
            } else {
                room.localParticipant.videoTracks.forEach(publication => {
                    publication.track.disable();
                });
            }

            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);

            room.participants.forEach(participantConnected);
        });

        return () => {
            setRoom(currentRoom => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();
                    return null;
                } else {
                    return currentRoom;
                }
            });
        };

    }, [roomName, token]);

    //To recognized device to allow switch camera functionality
    function gotDevices(mediaDevices) {
        let index = 0, devices = [];
        mediaDevices.forEach(mediaDevice => {
            if (mediaDevice.kind === 'videoinput') {
                devices[index++] = mediaDevice.deviceId;
            }
        });
        setCameraDevices(devices);
    }

    //Set Token Details
    if ('undefined' !== typeof (tokenDetails)
        && 'undefined' !== typeof (tokenDetails.tokenInfo)) {

        if ('undefined' !== typeof (tokenDetails.tokenInfo.userType) && '' === userType) {
            setUserType(tokenDetails.tokenInfo.userType);
        }

        if ('undefined' !== typeof (tokenDetails.tokenInfo.roomName) && '' === roomName) {
            setRoomName(tokenDetails.tokenInfo.roomName);
        }

        if ('undefined' !== typeof (tokenDetails.tokenInfo.token) && '' === token) {
            setToken(tokenDetails.tokenInfo.token);
        }
    }

    //START- Calculating the Network Quality Score Only for Agent
    let intervalID = 0;
    let netScore = [];
    let elemEndTourBtn = document.getElementById("endTourBtn");
    const printNetworkQualityStats = (networkQualityLevel, networkQualityStats) => {
        if (0 !== intervalID) {
            clearInterval(intervalID);
        }
        if (room) {
            intervalID = setInterval(() => {
                if ('undefined' !== typeof (networkQualityStats)) {
                    if ('undefined' !== typeof (networkQualityStats.level)) {
                        netScore.push(networkQualityStats.level);
                        elemEndTourBtn.setAttribute('netScore', JSON.stringify(netScore));
                    }
                }
            }, 1000);
        }
    };
    //END- Calculating the Network Quality Score Only for Agent

    //START- Check Prospect and Load Prospect Video
    const remoteParticipants = participants.map((participant) => {
        if (false === participant.identity.includes('*agent')) {
            //Loading Placeholder until Prospect is joined
            if (document.getElementById('prospect-section').classList.contains("placeholder")) {
                document.getElementById('prospect-section').classList.remove('placeholder');
                removeElements(document.querySelectorAll(".prospect-waiting-text"));
            }
            return <Participant key={participant.sid} participant={participant}/>
        }
    });
    //END- Check Prospect and Load Prospect Video


    //START- Check Agent and Load Agent Video
    const remoteAgent = participants.map((participant) => {
        if (true === participant.identity.includes('*agent')) {
            //Loading Placeholder until Agent is joined
            if (document.getElementById('agent-section').classList.contains("placeholder")) {
                document.getElementById('agent-section').classList.remove('placeholder');
                removeElements(document.querySelectorAll(".agent-waiting-text"));
            }
            participant.on("networkQualityLevelChanged", printNetworkQualityStats); // Calling Network Quality fun in case of agent only
            return <Participant key={participant.sid} enableScreenShare="true" videoPriority="high"
                                participant={participant}/>
        }
    });
    //END- Check Agent and Load Agent Video

    //START - Share Screen to Prospects
    if (room && userType !== 'Agent') {
        room.on('trackSubscribed', screenTrackSubscribed);
        room.on('trackUnsubscribed', screenTrackUnsubscribed);
    }
    //End - Share Screen to Prospects

    //START - End Tours
    const handleConfirm = useCallback(event => {
        logout(tokenDetails.tokenInfo.userType, event, handleLogout);

    }, []);
    if (room) {
        setInterval(() => {
            endTour(room, handleLogout);
        }, 3000);
    }
    //END - End Tours


    let localParticipant = room ? room.localParticipant : ''
    let agentName = ('undefined' != typeof (data.loginInfo.data.agent_name)) ? data.loginInfo.data.agent_name : '';
    let screenShareFlag = ('undefined' != typeof (data.loginInfo.data.allow_screenshare)) ? data.loginInfo.data.allow_screenshare : true;
    let strStartTime = new Date().getTime();
    let render = '';
    let deviceType = getDeviceType();
    let prospectVGTMeetingURL = ('undefined' != typeof (data.loginInfo.data.prospect_vgt_meeting_url)) ? data.loginInfo.data.prospect_vgt_meeting_url : '';

    render = (
        <>
            {
                (userType === 'Agent') ? (
                    <>
                        <video className="agent-section share-screen share-off" autoPlay id="screen-view"></video>
                        <div id="agent-section" className="agent-section">
                            {room ? (
                                <>
                                    <Participant
                                        key={room.localParticipant.sid}
                                        participant={room.localParticipant}
                                    />

                                </>
                            ) : ('')}

                        </div>
                        {
                            (
                                room
                                && deviceType !== 'desktop'
                            ) ? (
                                <div className="actions fa-img switch-camera-parentDiv">
                                    <FontAwesomeIcon id="switch-camera" className="switch-camera"
                                                     onClick={() => dispatch(toggleCamera(localParticipant, cameraDevices))}
                                                     icon={faSyncAlt}
                                                     size="2x"/>
                                </div>) : (
                                ''
                            )}
                    </>
                ) : (
                    <>
                        <div className="agent-section share-screen share-off" id="remote-screen-view"></div>
                        <div id="agent-section" className="agent-section placeholder">
                            <p className="waiting agent-waiting-text">Waiting for {agentName} to join...</p>
                            {remoteAgent}
                        </div>
                    </>
                )
            }

            <footer id="footer">
                <div className="footer-elements clearfix clear">
                    <div id="prospect-section" className="prospect-section col-lg-12 placeholder">
                        {
                            (userType === 'Agent') ? (
                                <>
                                    <p className="waiting prospect-waiting-text">Waiting for participants to join...</p>
                                    {remoteParticipants}
                                </>
                            ) : (
                                <>
                                    {room ? (
                                        <Participant
                                            key={room.localParticipant.sid}
                                            participant={room.localParticipant}
                                        />
                                    ) : ('')
                                    }
                                    {remoteParticipants}
                                </>
                            )
                        }

                    </div>
                    <div className="control-section col-lg-12">
                        <div className="control-group-items justify-content-center clearfix clear">
                            <div className="icon-group">
                                {
                                    room ? (
                                        <>
                                            <div className="actions fa-img">
                                                <FontAwesomeIcon
                                                    onClick={() => {
                                                        dispatch(toggleVideoTrack(localParticipant, userType));
                                                    }}
                                                    icon={roomData.videoIcon}
                                                    size="2x"/>
                                            </div>
                                            <div className="actions fa-img">
                                                <FontAwesomeIcon
                                                    onClick={() => {
                                                        dispatch(toggleAudioTrack(localParticipant));
                                                    }}
                                                    icon={roomData.audioIcon}
                                                    size="2x"/>
                                            </div>

                                            {
                                                (userType === 'Agent' && deviceType === 'desktop' && screenShareFlag === true) ? (
                                                    <div className="actions fa-img">
                                                        <FontAwesomeIcon id="shareIcon"
                                                                         onClick={() => {
                                                                             dispatch(shareScreen(localParticipant));
                                                                         }}
                                                                         icon={faTv}
                                                                         size="2x"/>
                                                    </div>) : ('')
                                            }

                                        </>
                                    ) : ('')
                                }
                            </div>
                            <div className="button-group">
                                <div className="actions">
                                    {
                                        room ? (
                                            <FontAwesomeIcon
                                                onClick={handleConfirm}
                                                data-time={strStartTime}
                                                data-roomsid={room.sid}
                                                icon={faPhoneSquare}
                                                size="2x"
                                                id="endTourBtn"
                                            />
                                        ) : ('')
                                    }
                                </div>
                                <div className="actions">
                                    {
                                        room && prospectVGTMeetingURL ? (
                                        <button id="copyurl"
                                                onClick={() => copyURL(prospectVGTMeetingURL)}
                                                type="button"
                                                className="btn btn-dark btn-sm">Invite
                                        </button>
                                        ) : ('')
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );

    return render;
}

export default RoomScreen;
