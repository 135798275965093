import {
    TWILIO_API_KEY,
    TWILIO_API_SECRET,
    TURN_ON_AUDIO,
    TURN_OFF_AUDIO,
    TURN_ON_VIDEO,
    TURN_OFF_VIDEO
} from "../constants/roomConstants";
import Video from "twilio-video";
import React from "react";
import {confirmAlert} from "react-confirm-alert";
import {MYSHOWING_URL} from "../constants/popupConstants";
import axios from "axios";
import qs from "qs"; // used to data stringify for twilio API
import store from '../store';
import {Buffer} from 'buffer';

/**
 * @function const getDeviceType
 * Description: To get current device type eg Mobile, Tablet, Desktop etc
 */
export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return "mobile";
    }
    return "desktop";
};

/**
 * @function const toggleAudioTrack
 * @param localParticipant
 * Description: To on/off audio during twilio call.
 */
export const toggleAudioTrack = (localParticipant) => async (dispatch) => {
    if (localParticipant.state === 'connected') {
        localParticipant.tracks.forEach(function (trackPublication) {
            if (trackPublication.track.kind === "audio" && true === trackPublication.track.isEnabled) {
                trackPublication.track.disable();
                dispatch({type: TURN_OFF_AUDIO, payload: TURN_OFF_AUDIO});
            } else if (trackPublication.track.kind === "audio" && false === trackPublication.track.isEnabled) {
                trackPublication.track.enable();
                dispatch({type: TURN_ON_AUDIO, payload: TURN_ON_AUDIO});
            }
        });
    }
}

/**
 * @function const toggleVideoTrack
 * @param localParticipant
 * Description: To on/off video during twilio call.
 */
export const toggleVideoTrack = (localParticipant) => async (dispatch) => {
    if (localParticipant.state === 'connected') {
        const storeData = store.getState(); // collect store data to verify userType
        const deviceType = getDeviceType(); // Fetch device type to Show/Hide switch icon if camera is off
        localParticipant.tracks.forEach(function (trackPublication) {
            if (trackPublication.track.kind === "video" && true === trackPublication.track.isEnabled) {
                trackPublication.track.disable();
                dispatch({type: TURN_OFF_VIDEO, payload: TURN_OFF_VIDEO});
                if (storeData.tokenDetails.tokenInfo.userType === 'Agent') {
                    setVideoAttr(localParticipant.identity, 'video_off', 'Agent');
                    if (deviceType !== 'desktop') {
                        document.getElementsByClassName('switch-camera')[0].parentElement.style.display = 'none';
                    }
                } else {
                    setVideoAttr(localParticipant.identity, 'video_off');
                }
                setDimensions(localParticipant.identity, 'video_off');
            } else if (trackPublication.track.kind === "video" && false === trackPublication.track.isEnabled) {
                trackPublication.track.enable();
                dispatch({type: TURN_ON_VIDEO, payload: TURN_ON_VIDEO});
                if (storeData.tokenDetails.tokenInfo.userType === 'Agent' && deviceType !== 'desktop') {
                    document.getElementsByClassName('switch-camera')[0].parentElement.style.display = 'block';
                }
                setVideoAttr(localParticipant.identity);
                setDimensions(localParticipant.identity);
            }
        });
    }

}

/**
 * @function const toggleCamera
 * @param localParticipant, cameraDevices
 * Description: To switch camera back/front during twilio call.
 */
export const toggleCamera = (localParticipant, cameraDevices) => async (dispatch) => {
    let lastIndex = cameraDevices.length - 1;
    if (sessionStorage.getItem('cameraIndex') === '0') {
        updateVideoDevice(lastIndex, cameraDevices, localParticipant);
        sessionStorage.setItem("cameraIndex", lastIndex.toString());
        document.getElementById('switch-camera').classList.add("back-cam");
    } else if (sessionStorage.getItem('cameraIndex') === lastIndex.toString()) {
        updateVideoDevice(0, cameraDevices, localParticipant);
        sessionStorage.setItem("cameraIndex", "0");
        document.getElementById('switch-camera').classList.remove("back-cam");
    }
};

/**
 * @function updateVideoDevice
 * @param deviceIndex, cameraDevices, localParticipant
 * Description: To update Video device, it is called to toggleCamera function
 */
function updateVideoDevice(deviceIndex, cameraDevices, localParticipant) {
    const tracks = Array.from(localParticipant.videoTracks.values()).map(
        function (trackPublication) {
            return trackPublication.track;
        }
    );
    localParticipant.unpublishTracks(tracks);
    detachTracks(tracks);
    stopTracks(tracks);
    Video.createLocalVideoTrack({
        deviceId: {exact: cameraDevices[deviceIndex]}
    }).then(async localVideoTrack => {
        if (localVideoTrack) {
            localParticipant.publishTrack(localVideoTrack);
            const previewContainer = document.getElementById('agent-section');
            if (previewContainer) {
                var hasVideo = previewContainer.querySelector("video") == null;
                if (previewContainer && hasVideo) {
                    attachTracks([localVideoTrack], previewContainer);
                }
            }
        }
    }).catch(handleMediaError);
}

/**
 * @function  attachTracks
 * @param tracks
 * Description: To attach Tracks
 */
function attachTracks(tracks, container) {
    tracks.forEach(track => {
        if (track) {
            addTrack(track, container);
        }
    });
}

/**
 * @function  addTrack
 * @param track
 * Description: To add Track
 */
function addTrack(track, container) {
    container.appendChild(track.attach());
}

/**
 * @function detachTracks
 * @param tracks
 * Description: To detach Tracks
 */
function detachTracks(tracks) {
    tracks.forEach(track => {
        if (track) {
            removeTrack(track);
        }
    });
}

/**
 * @function  removeTrack
 * @param track
 * Description: To remove Track
 */
function removeTrack(track) {
    track.detach().forEach(detachedElement => {
        detachedElement.remove();
    });
}

/**
 * @function stopTracks
 * @param tracks
 * Description: To stop Tracks
 */
function stopTracks(tracks) {
    tracks.forEach(track => {
        if (track) {
            track.stop();
        }
    })
}

/**
 * @function  handleMediaError
 * @param error
 * Description: To display Device Media Error
 */
function handleMediaError(error) {
    console.error('Failed to acquire media:', error.name, error.message);
}

/**
 * @function  copyURL
 * Description: To copy meeting url by click on 'Invite' Button
 * @param prospectVGTMeetingURL
 */
export const copyURL = (prospectVGTMeetingURL) => {
    let elem = document.getElementById("copyurl");
    let start = Date.now(); // remember start time
    let timer = setInterval(function () {
        // how much time passed from the start?
        let timePassed = Date.now() - start;
        if (timePassed >= 2000) {
            elem.innerText = "Invite";
            clearInterval(timer); // finish the animation after 2 seconds
            return;
        }
        let input = document.body.appendChild(document.createElement("input"));
        input.value = prospectVGTMeetingURL;
        input.focus();
        input.select();
        document.execCommand('copy');
        input.parentNode.removeChild(input);
        elem.innerText = "Copied";
    }, 20);

};

/**
 * @function  logout
 * @params userType, event, handleLogout
 * Description: It is common logout function for agent/prospect, A confirm alert box will be populated
 * Agent Logout - If Agent logout then agentLogout function will invoke and it unset the room session
 * Guest Logout- Guest will be logout without unset room session
 */
export const logout = (userType, event, handleLogout) => {
    let netScore = event.currentTarget.getAttribute("netScore");
    let strStartTime = event.currentTarget.getAttribute("data-time");
    let roomSID = event.currentTarget.getAttribute("data-roomsid");
    let endTime = new Date().getTime();
    let strAppointmentID = localStorage.getItem('appointmentId')

    if (null !== netScore && 0 !== netScore.length) {
        // Calculate the average Score
        netScore = JSON.parse(netScore);
        let total = 0;
        netScore.forEach(function (x) {
            total = total + x;
        });
        netScore = total / netScore.length;
        netScore = netScore.toFixed(2);
        //
    } else {
        netScore = 0;
    }

    confirmAlert({
        title: '',
        message: 'Are you sure you want to end this tour?',
        buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger float-left',
                onClick: () => {
                    if ('' !== strStartTime && '' !== strAppointmentID && userType === 'Agent') {
                        let timeDuration = endTime - strStartTime;
                        const API_PATH = MYSHOWING_URL + 'updateAppointment/' + strAppointmentID + '/' + timeDuration + '/' + netScore;
                        axios({
                            method: 'get',
                            url: `${API_PATH}`,
                            headers: {'content-type': 'application/json'}
                        }).then(result => {
                            console.log(result);
                        }).catch(error => {
                            console.log(error);
                        });
                        //End Tour by Agents
                        agentLogout(userType, roomSID, handleLogout);
                    } else {
                        handleLogout(userType);
                    }
                }
            },
            {
                label: 'Cancel',
                className: 'btn btn-dark float-right',
            }
        ],
        childrenElement: () => <div/>,
    });
};

/**
 * @function  agentLogout
 * @params userType, roomSID, handleLogout
 * Description: If Agent logout then agentLogout function will invoke and it unset the room session
 */
async function agentLogout(userType, roomSID, handleLogout) {
    if (roomSID) {
        await axios({
            method: 'post',
            url: `https://video.twilio.com/v1/Rooms/` + roomSID,
            data: qs.stringify({
                Status: 'completed'
            }),
            headers: {
                'Authorization': 'Basic ' + Buffer.from(`${TWILIO_API_KEY}:${TWILIO_API_SECRET}`).toString('base64'),
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            }
        }).then(result => {
            handleLogout(userType); //Set Token as null to logout
        }).catch(error => {
            console.log(error);
        });
    }
}

/**
 * @function  endTour
 * @params room, handleLogout, intervalCount
 * Description: If Agent logout then agentLogout function will invoke
 * and it unset the room session and this endTour function checks in every 3 sec (Checked in roomScreen component)
 * that room session is exist or not if not then it end the call for guest with message "Host has ended the call"
 * and redirect prospect to survey page
 */
let intervalCount = 0;
export const endTour = (room, handleLogout) => {
    const storeData = store.getState();
    if (storeData.tokenDetails.tokenInfo.userType !== 'Agent') {
        let prospectWindow = document.getElementById('prospect-section'); // to confirm agent ended the tour
        if (room && prospectWindow !== null) {
            if (room.state === 'disconnected') {
                if (intervalCount === 0) {
                    confirmAlert({
                        title: '',
                        message: 'Host has ended the call.',
                        buttons: [
                            {
                                label: 'Yes',
                                className: 'btn btn-danger text-center',
                                onClick: () => {
                                    handleLogout(storeData.tokenDetails.tokenInfo.userType);
                                }
                            }
                        ],
                        childrenElement: () => <div/>,
                    });
                }
                setInterval(() => {
                    if (intervalCount === 6) {
                        handleLogout(storeData.tokenDetails.tokenInfo.userType);
                    }
                    intervalCount++;
                }, 1000);
            }
        }
    }
}

/**
 * @function  shareScreen
 * @param localParticipant
 * Description: Share Screen Functionality
 */
let screenTrack;
export const shareScreen = (localParticipant) => async (dispatch) => {
    if (document.getElementById('screen-view').classList.contains("share-off")) {
        navigator.mediaDevices.getDisplayMedia({
            video: true
        }).then(
            stream => {
                screenTrack = stream.getVideoTracks()[0];
                screenTrack.onended = function () {  // somebody clicked on "Stop sharing" from browser interface
                    stopSharing(localParticipant, screenTrack);
                };
                try {
                    localParticipant.publishTrack(screenTrack, {name: 'screen'});
                    const video = document.getElementById("screen-view");
                    document.getElementById('screen-view').classList.remove('share-off');
                    document.getElementById('shareIcon').style.color = '#dc3545';
                    video.srcObject = stream;

                    // To fix mirroring issue
                    let isSelfShareAdd = document.getElementById("self-share-overlay");
                    let header = document.getElementById("room-header");
                    let node = document.createElement("DIV");
                    node.innerHTML = '<p>To avoid mirroring, just share a different tab or window instead.</p>';
                    node.setAttribute("id", "self-share-overlay");
                    node.setAttribute("class", "self-share-overlay");
                    if (!isSelfShareAdd) {
                        header.parentNode.insertBefore(node, header.nextSibling);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        );
    } else {
        stopSharing(localParticipant, screenTrack);
    }
}

/**
 * @function  stopSharing
 * @param localParticipant
 * @param screenTrack
 * Description: Stop Share Screen Functionality
 */
function stopSharing(localParticipant, screenTrack) {
    localParticipant.unpublishTrack(screenTrack);
    screenTrack.stop();
    let isSelfShareRemove = document.getElementById("self-share-overlay");
    isSelfShareRemove.remove();
    screenTrack = null;
    document.getElementById('screen-view').classList.add('share-off');
    document.getElementById('shareIcon').style.color = '#212529';
}

/**
 * @function  screenTrackSubscribed
 * @param track
 * Description: Invoke when Track is subscribed
 */
export const screenTrackSubscribed = (track) => {
    if (track.name === 'screen') {
        removeTrack(track);
        addTrack(track, document.getElementById('remote-screen-view'));
        document.getElementById('remote-screen-view').classList.remove('share-off');
    }
};

/**
 * @function  screenTrackUnsubscribed
 * @param track
 * Description: Invoke when Track is unsubscribed
 */
export const screenTrackUnsubscribed = (track) => {
    if (track.name === 'screen') {
        removeTrack(track);
        document.getElementById('remote-screen-view').classList.add('share-off');
    }
};

/**
 * @function  handleSpeakerChange
 * @params participant
 * Description: highlighting the loudest participant on dominantSpeakerChanged Event
 */
export const handleSpeakerChange = (participant) => {
    removeDominantSpeaker();
    if (participant !== null)
        assignDominantSpeaker(participant);
}

let lastSpeakerSID = null; // add this at the top with the other variable declarations

/**
 * @function  setLabelColor
 * @params label, color
 * Description: change border color for loudest participant
 */
function setLabelColor(label, color) {
    if (label !== null) {
        label.style.border = color;

    }
}

/**
 * @function  removeDominantSpeaker
 * Description: remove border color once speaker has changed
 */
function removeDominantSpeaker() {
    let speakerNameLabel;
    let participantVideo;
    if (lastSpeakerSID !== null) {
        speakerNameLabel = document.getElementById(lastSpeakerSID);
        for (let i = 0; i < speakerNameLabel.childNodes.length; i++) {
            participantVideo = speakerNameLabel.childNodes[i];
            if (participantVideo.style.display === 'table-cell' || participantVideo.style.display === 'block') {
                setLabelColor(document.getElementById(participantVideo.id), "none"); // green color
            }
        }
    }

}

/**
 * @function  assignDominantSpeaker
 * @params participant
 * Description: add border color for loudest speaker has changed
 */
function assignDominantSpeaker(participant) {
    let domSpeakerNameLabel;
    let participantVideo;
    lastSpeakerSID = participant.identity.replaceAll(" ", "_");
    domSpeakerNameLabel = document.getElementById(lastSpeakerSID);
    for (let i = 0; i < domSpeakerNameLabel.childNodes.length; i++) {
        participantVideo = domSpeakerNameLabel.childNodes[i];
        if (participantVideo.style.display === 'table-cell' || participantVideo.style.display === 'block') {
            setLabelColor(document.getElementById(participantVideo.id), "2px solid #87CF36"); // green color
        }
    }
}

/**
 * @function  updateParticipantUI
 * @param track
 * $param identity
 * Description: To Update Track
 */
export const updateParticipantUI = (track, identity) => {
    if (track.isEnabled === true) {
        setVideoAttr(identity);
        setDimensions(identity);
        if (track.name === 'screen' && false === track.mediaStreamTrack.enabled && identity.includes('Agent')) {
            setVideoAttr(identity, 'video_off', 'Agent');
            setDimensions(identity, 'video_off');
        }
    } else {
        if (identity.includes('Agent')) {
            setVideoAttr(identity, 'video_off', 'Agent');
        } else {
            setVideoAttr(identity, 'video_off');
            setDimensions(identity, 'video_off');
        }
    }
}

function setVideoAttr(participantIdentity, videoSwitch = 'video', userType = '') {
    participantIdentity = participantIdentity.replaceAll(" ", "_");
    let videoObj = document.getElementById(participantIdentity + '_video');
    let videoOffDiv = document.getElementById(participantIdentity + '_video_off');
    if (videoSwitch === 'video_off') {
        videoObj.style.display = 'none';
        videoOffDiv.style.display = 'flex';
        videoOffDiv.parentElement.style = 'display:flex;justify-content:center;';
        if (userType === 'Agent') {
            document.getElementById('agent-section').style.backgroundColor = '#C0C0C0';
            videoOffDiv.style.flexDirection = 'column';
            videoOffDiv.style.height = '6.25em';
            videoOffDiv.style.border = 'none';
            videoOffDiv.style.width = 'auto';
        }
    } else {
        videoOffDiv.style.display = 'none';
        videoObj.style.display = 'table-cell';
        videoObj.parentElement.style = 'display:flex;justify-content:center;';
    }
}

function setDimensions(participantIdentity, videoSwitch = 'video') {
    let videoHeight, cssWidth, cssHeight;
    participantIdentity = participantIdentity.replaceAll(" ", "_");
    let deviceType = getDeviceType();
    if (participantIdentity.includes('Agent')) {
        if (deviceType === 'mobile') {
            cssWidth = '100%';
        } else {
            cssWidth = '10vw';
        }
    } else {
        if (deviceType === 'tablet') {
            cssHeight = '4em';
            cssWidth = '38%';
        } else if (deviceType === 'mobile') {
            cssHeight = '3.75em';
            cssWidth = '17.25vw';
        } else {
            cssHeight = '15vh';
            cssWidth = '10vw';
        }
    }
    let videoObj = document.getElementById(participantIdentity + '_video');

    if (videoObj) {
        videoObj.style.width = cssWidth;
    }

    let prospect_videos = document.getElementById("prospect-section").getElementsByClassName("video-off");
    let video_off_count = 0;
    for (let i = 0; i < prospect_videos.length; i++) {
        let computedStyle = getComputedStyle(prospect_videos.item(i));
        if (computedStyle.display === 'flex') {
            video_off_count++;
        }
    }
    for (let i = 0; i < prospect_videos.length; i++) {
        if (prospect_videos.length === video_off_count) {
            prospect_videos.item(i).style.height = cssHeight;
        } else {
            prospect_videos.item(i).style.width = cssWidth;
        }
    }

}
