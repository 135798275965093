import {FETCH_TOKEN_FAIL, FETCH_TOKEN_REQUEST, FETCH_TOKEN_SUCCESS} from "../constants/tokenConstants";

export const fetchVideoToken = (identity, roomName, userName) => async (dispatch) => {
    dispatch({type: FETCH_TOKEN_REQUEST, payload: []});
    try {
        const data = await fetch('/video/token', {
            method: 'POST',
            body: JSON.stringify({
                identity: identity,
                room: roomName,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const response = await data.json();
        response.userType = userName;
        response.roomName = roomName;
        dispatch({type: FETCH_TOKEN_SUCCESS, payload: response});
    } catch (error) {
        dispatch({
            type: FETCH_TOKEN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}