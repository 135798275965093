import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchAppointment, fetchParticipantsCount, setUpperCaseName} from "../actions/popupActions";
import {fetchVideoToken} from "../actions/tokenAction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import store from "../store";
import RoomScreen from "./RoomScreen";
import {
    MYSHOWING_URL,
} from "../constants/popupConstants";
import {confirmAlert} from "react-confirm-alert";

export default function PopupScreen(props) {

    const dispatch = useDispatch();
    const appointmentId = props.match.params.appointmentId;
    localStorage.setItem('appointmentId', appointmentId);
    const [agentName, setAgentName] = useState('');
    const [token, setToken] = useState('');
    const [roomTitle, setRoomTitle] = useState('');
    const [loader, setLoader] = useState(1);
    const [closePopup, setClosePoup] = useState(0);
    const [showError, setShowError] = useState(0)
    const [errorMsg, setErrorMsg] = useState('')
    const [userLogout, setUserLogout] = useState(0);
    const [userType, setUserType] = useState('');
    const data = useSelector(state => state.loginDetails);

    let roomName = ' Virtual guided tour';
    let roomAppointmentId;

    //Fetch Appointment On load Action
    useEffect(() => {
        if ('' === agentName) {
            dispatch(fetchAppointment(appointmentId));
        }
    }, [dispatch, appointmentId, agentName]);

    //Load Popup
    useEffect(() => {
        if ('undefined' !== typeof (data)
            && 'undefined' !== typeof (data.loginInfo)
            && 'undefined' !== typeof (data.loginInfo.data)) {
            if (1 === loader && 0 === userLogout) {
                setLoader(0);
            }
            if (data.loginInfo.data.hasOwnProperty('error')) {
                if (0 === showError) {
                    setShowError(1);
                    setErrorMsg('This meeting is not available.');
                }
            } else if (data.loginInfo.data.meeting_msg === true) {
                if (0 === showError) {
                    setShowError(1);
                    setErrorMsg('Your meeting is scheduled at ' + data.loginInfo.data.start_time + ' on ' + data.loginInfo.data.appointment_date + '.');
                }
            } else if (data.loginInfo.data.appointment_overed === true) {
                if (0 === showError) {
                    setShowError(1);
                    setErrorMsg('Sorry, the appointment time is over.');
                }
            } else if (sessionStorage.getItem('limit')) {
                setShowError(1);
                setErrorMsg('Max capacity reached.');
                sessionStorage.removeItem("limit");
            } else {
                if ('undefined' !== typeof (data.loginInfo.data.user_type)) {
                    setUserType(data.loginInfo.data.user_type);
                }
                if ('undefined' !== typeof (data.loginInfo.data.agent_name)) {
                    if ('' === agentName) {
                        setAgentName(setUpperCaseName(data.loginInfo.data.agent_name));
                    }
                }
                if ('undefined' !== typeof (data.loginInfo.data.prospect_name)
                    && 'undefined' !== typeof (data.loginInfo.data.property_name)) {
                    roomName = setUpperCaseName(data.loginInfo.data.prospect_name) + roomName + ' at ' + data.loginInfo.data.property_name;
                    if ('' === roomTitle) {
                        setRoomTitle(roomName);
                    }
                }
                if ('undefined' !== typeof (data.loginInfo.data.appointment_id)) {
                    roomAppointmentId = data.loginInfo.data.appointment_id;
                }
            }
        }

        if (token === '' || errorMsg !== '') {
            const options = {

                customUI: ({onClose}) => {
                    if (loader === 1) {
                        return (
                            <div className="room-popup col-lg-12 text-center">
                                <FontAwesomeIcon icon={faSpinner} spin size="3x" color="gray"/>
                            </div>
                        )
                    } else {
                        if (1 === showError) {
                            return (
                                <div className="room-popup col-lg-12 text-center">
                                    <p className="error-msg">{errorMsg}</p>
                                </div>
                            )
                        } else {
                            handleLogin();
                            if (closePopup === 1) {
                                onClose();
                            }
                            return (
                                <div className="room-popup col-lg-12 text-center">
                                    {(1 === showError) ? (
                                        <FontAwesomeIcon icon={faSpinner} spin size="3x" color="gray"/>
                                    ) : (
                                        <p className="error-msg">{errorMsg}</p>
                                    )}
                                </div>
                            )
                        }
                    }
                },
                closeOnEscape: true,
                closeOnClickOutside: false,
                overlayClassName: "loader-popup"
            };
            confirmAlert(options);
        }
    });

    //Get Video Token
    const getVideoToken = async (identity, roomName, userName) => {
        await dispatch(fetchVideoToken(identity, roomName, userName));
        let tokenDetails = store.getState().tokenDetails;
        if ('undefined' !== typeof (tokenDetails)
            && 'undefined' !== typeof (tokenDetails.tokenInfo)
            && 'undefined' !== typeof (tokenDetails.tokenInfo.token)) {
            setToken(tokenDetails.tokenInfo.token);
        }
    }

    //Get Participant Count
    const getParticipantsCount = async (roomName, userType) => {
        await dispatch(fetchParticipantsCount(roomName, userType));
        let data = store.getState().participantDetails;
        return data.participantInfo;
    }

    //Auto Login
    const handleLogin = () => {
        if (userType === 'Agent') {
            getParticipantsCount(roomAppointmentId, 'Agent').then(response => {
                if (response.count === 1) {
                    setShowError(1);
                    setErrorMsg('Max capacity reached!!');
                } else {
                    let userName = 'Agent';
                    let identity = userName + '*agent';
                    getVideoToken(identity, roomAppointmentId, userName);
                    setClosePoup(1);
                }
            });
        } else if (userType === 'Prospect') {
            getParticipantsCount(roomAppointmentId, 'Guest').then(response => {
                if (response.count === 3) {
                    setShowError(1);
                    setErrorMsg('Max capacity reached!!');
                } else {
                    let userName = 'Guest: ' + Math.floor((Math.random() * 9999) + 1);
                    let identity = userName + '*prospect';
                    getVideoToken(identity, roomAppointmentId, userName);
                    setClosePoup(1);
                }
            });
        } else {
            setShowError(1);
            setErrorMsg('Invalid User!');
        }
    }

    //Logout
    const handleLogout = (userType) => {
        let strSurveySFID = '';
        let strAgentSFID = '';
        let strPropertySFID = '';
        let strApptSFID = '';
        let strProspectSFID = '';
        if ('undefined' !== typeof (data)
            && 'undefined' !== typeof (data.loginInfo)
            && 'undefined' !== typeof (data.loginInfo.data)
            && 'undefined' !== typeof (data.loginInfo.data.survey_sfid)
            && 'undefined' !== typeof (data.loginInfo.data.agent_sfid)
            && 'undefined' !== typeof (data.loginInfo.data.property_sfid)
            && 'undefined' !== typeof (data.loginInfo.data.appt_sfid)
            && 'undefined' !== typeof (data.loginInfo.data.prospect_id)
        ) {
            strSurveySFID = data.loginInfo.data.survey_sfid;
            strAgentSFID = data.loginInfo.data.agent_sfid;
            strPropertySFID = data.loginInfo.data.property_sfid;
            strApptSFID = data.loginInfo.data.appt_sfid;
            strProspectSFID = data.loginInfo.data.prospect_id;
            setToken('');
            setUserLogout(1);
            if (0 === loader) {
                setLoader(1);
            }
            sessionStorage.removeItem('cameraIndex');
            if (userType === 'Agent') {
                console.log('Agent Logout');
                window.location = MYSHOWING_URL + strSurveySFID + "/" + strPropertySFID + "/" + strAgentSFID + "/0/0/0/" + strApptSFID;
            } else {
                console.log('Guest Logout');
                window.location = MYSHOWING_URL + strSurveySFID + "/" + strPropertySFID + "/" + strProspectSFID + "/0/0/0/" + strApptSFID;
            }
        }
    };

    let render = '';
    if (token !== '') {
        render = (
            <div className="main-content">
                <header id="room-header" className="room-header text-center bg-opacity">
                    <h1>{roomTitle}</h1>
                </header>
                <RoomScreen
                    handleLogout={handleLogout}
                />
            </div>);
    }
    return render;
} 