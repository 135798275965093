import React, {useState, useEffect, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVideoSlash} from "@fortawesome/free-solid-svg-icons";
import {updateParticipantUI} from "./actions/roomActions";

const Participant = ({participant, status}) => {
    const [videoTracks, setVideoTracks] = useState([]);
    const [audioTracks, setAudioTracks] = useState([]);
    const videoRef = useRef();
    const audioRef = useRef();

    const trackpubsToTracks = (trackMap) =>
        Array.from(trackMap.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null);

    useEffect(() => {
        if (!videoTracks.length) {
            participant.videoTracks.forEach(publication => {
                if (publication && publication.track) {
                    updateParticipantUI(publication.track, participant.identity);
                }
            });
        }
        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.audioTracks));

        const trackSubscribed = (track) => {
            if (track.kind === "video") {
                participant.videoTracks.forEach(publication => {
                    if (publication.track !== null) {
                        if ((false === publication.track.isEnabled || true === participant.identity.includes('*agent')) || true === publication.track.isEnabled) {
                            updateParticipantUI(track, participant.identity);
                        }
                    }
                });

                track.on('disabled', () => updateParticipantUI(track, participant.identity));
                track.on('enabled', () => updateParticipantUI(track, participant.identity));
                setVideoTracks((videoTracks) => [...videoTracks, track]);
            } else if (track.kind === "audio") {
                setAudioTracks((audioTracks) => [...audioTracks, track]);
            }
        };

        const trackUnsubscribed = (track) => {
            if (track.kind === "video") {
                participant.videoTracks.forEach(publication => {
                    if (null !== publication.track) {
                        if (("screen" === track.name && true === participant.identity.includes('*agent')) || true === publication.track.isEnabled) {
                            publication.track.detach();
                            updateParticipantUI(publication.track, participant.identity);
                        } 
                    }
                });

                setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
            } else if (track.kind === "audio") {
                setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
            }
        };

        participant.on("trackSubscribed", trackSubscribed);
        participant.on("trackUnsubscribed", trackUnsubscribed);

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
        };
    }, [participant]);

    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoTrack) {
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);
    let identity = participant.identity.replaceAll(" ", "_");

    if ((identity.split('*')[1]) === 'agent') {
        return (
            <>
                <div className="video-off" id={`${identity}_video_off`}>
                    <h5>Agent's camera turned off.</h5>
                    <FontAwesomeIcon icon={faVideoSlash} size="3x"/>
                </div>
                <video ref={videoRef} autoPlay={true}
                       id={`${identity}_video`}/>
                <audio ref={audioRef} autoPlay={true}/>
            </>

        );
    } else {
        return (
            <div className="prospect col-lg-4 col-sm-4 col-4" id={`${identity}`}>
                <div className="video-off" id={`${identity}_video_off`}>
                    <FontAwesomeIcon icon={faVideoSlash} size="2x"/>
                </div>
                <video ref={videoRef} autoPlay={true}
                       id={`${identity}_video`}/>
                <audio ref={audioRef} autoPlay={true}/>
            </div>
        );
    }
};
export default Participant;
