import {
    FETCH_APPOINTMENT_FAIL,
    FETCH_APPOINTMENT_REQUEST,
    FETCH_APPOINTMENT_SUCCESS,
    FETCH_PARTICIPANT_FAIL,
    FETCH_PARTICIPANT_REQUEST,
    FETCH_PARTICIPANT_SUCCESS
} from "../constants/popupConstants";


export const popupReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_APPOINTMENT_REQUEST:
            return {loading: true};
        case FETCH_APPOINTMENT_SUCCESS:
            return {loading: false, loginInfo: action.payload};
        case FETCH_APPOINTMENT_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}

export const participantReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_PARTICIPANT_REQUEST:
            return {loading: true};
        case FETCH_PARTICIPANT_SUCCESS:
            return {loading: false, participantInfo: action.payload};
        case FETCH_PARTICIPANT_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}