import React from 'react';
import './App.css';
import {BrowserRouter, Route} from 'react-router-dom';
import PopupScreen from "./screens/PopupScreen";

const App = () => {
    return (
        <BrowserRouter>
            <Route path='/:appointmentId' component={PopupScreen}/>
        </BrowserRouter>
    );
};

export default App;
