import Axois from "axios";
import {Buffer} from 'buffer';
import qs from "qs";
import {
    FETCH_APPOINTMENT_FAIL,
    FETCH_APPOINTMENT_REQUEST,
    FETCH_APPOINTMENT_SUCCESS,
    FETCH_PARTICIPANT_FAIL,
    FETCH_PARTICIPANT_REQUEST,
    FETCH_PARTICIPANT_SUCCESS,
    MYSHOWING_URL
} from "../constants/popupConstants";
import {TWILIO_API_KEY, TWILIO_API_SECRET} from "../constants/roomConstants";
/**
 * @param appointmentId
 * Description: It fetches appointment from Lockbox
 */
export const fetchAppointment = (appointmentId) => async (dispatch) => {
    dispatch({type: FETCH_APPOINTMENT_REQUEST, payload: appointmentId});
    try {
        const {data} = await Axois.get(`${MYSHOWING_URL}fetchvgtapptdetails/${appointmentId}`);
        dispatch({type: FETCH_APPOINTMENT_SUCCESS, payload: {data}});
    } catch (error) {
        dispatch({
            type: FETCH_APPOINTMENT_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

/**
 *
 * @param roomName
 * @param userType
 * Description: It fetches connected participants for room with specified room name
 * @returns count for specified userType
 */
export const fetchParticipantsCount = (roomName, userType) => async (dispatch) => {
    dispatch({type: FETCH_PARTICIPANT_REQUEST, payload: roomName});
    try {
        const {data} = await Axois({
            method: 'get',
            url: 'https://video.twilio.com/v1/Rooms/' + roomName + '/Participants/',
            data: qs.stringify({
                Status: 'connected'
            }),
            headers: {
                'Authorization': 'Basic ' + Buffer.from(`${TWILIO_API_KEY}:${TWILIO_API_SECRET}`).toString('base64'),
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            }
        });
        let count = 0;
        if ('undefined' != typeof data
            && 'undefined' != typeof data.participants) {
            let participants = data.participants;
            for (let i = 0; i < participants.length; i++) {
                if (participants[i].identity.includes(userType)) {
                    count++;
                }
            }
        }
        dispatch({type: FETCH_PARTICIPANT_SUCCESS, payload: {count}});
    } catch (error) {
        dispatch({
            type: FETCH_PARTICIPANT_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

/**
 * @param name
 * Description: setUpperCase
 * @returns return converted Case
 */
export const setUpperCaseName = (name) => {
    return name.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toUpperCase() : word.toLowerCase();
    }).replace(/\s+/g, '');
}
