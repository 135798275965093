import {createStore, compose, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {popupReducer, participantReducer} from "./reducers/popupReducers";
import {tokenReducer} from "./reducers/tokenReducers";
import {roomReducer} from "./reducers/roomReducers";

const initialState = {};
const reducer = combineReducers({
    loginDetails: popupReducer,
    participantDetails: participantReducer,
    tokenDetails: tokenReducer,
    roomDetail: roomReducer
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)));

export default store;