import {FETCH_TOKEN_FAIL, FETCH_TOKEN_REQUEST, FETCH_TOKEN_SUCCESS} from "../constants/tokenConstants";

export const tokenReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_TOKEN_REQUEST:
            return {loading: true};
        case FETCH_TOKEN_SUCCESS:
            return {loading: false, tokenInfo: action.payload};
        case FETCH_TOKEN_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
}